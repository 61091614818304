import React from 'react';

const colors = {
  green: '#139982',
  lightGreen: '#61B195',
  yellow: '#E2A816',
  orange: '#F99746',
  grey3: '#828282',
  lighGrey: '#F1F1F1',
  pastelBlue: '#A1C6DA',
  pastelBrown: '#CCC3BA',
  pastelGreen: '#9EBEB8',
  pastelBeige: '#F0CFC4',
  pastelPurple: '#BE9EBB',
  pastelYellow: '#F0E1C4',
  greyDark: '#575761',
  grey: '#d9d9d9',
  purple: '#9EA2BE',
  black: '#282F33',
  darkGrey: 'rgba(40,47,51,0.5)',
  blueDark: '#2F4A67',
  beige: '#F9F3EB',
  red: '#D84C42',
  feideBlue: '#1F4698',
  blue: 'rgba(51, 160, 221, 1)',
  blueLight: 'rgba(51, 160, 221, 0.1)',
  pink: '#ff00ff',
  aqua: '#00FFFF',
  transparrentWhite: 'rgba(255, 255, 255, 0.9)',
  transparrentBlack: 'rgba(0, 0, 0, 0.9)',
};

export const theme = {
  colors: {
    background: colors.beige,
    orange: colors.orange,
    divider: colors.grey,
    green: colors.green,
    lightGreen: colors.lightGreen,
    primary: colors.green,
    purple: colors.purple,
    blue: colors.blue,
    blueLight: colors.blueLight,
    blueDark: colors.blueDark,
    white: '#fff',
    spanBackgroundWhite: colors.transparrentWhite,
    tooltipBackgroundBlack: colors.transparrentBlack,
    black: colors.black,
    iconGrey: colors.grey3,
    yellow: colors.yellow,
    aqua: colors.aqua,
    pink: colors.pink,
    red: colors.red,
    feideBlue: colors.feideBlue,
    greyLight: colors.lighGrey,
    greyDark: colors.greyDark,
    text: {
      light: '#bdbdbd',
      dark: '#4F4F4F',
      medium: '#A9A8A8',
      darkGrey: colors.darkGrey,
      grey: '#8C8C8C',
    },
    chart: {
      p95: colors.pastelBlue,
      p99: colors.pastelGreen,
      avg: colors.orange,
      globalP95: colors.pastelBrown,
      globalP99: colors.pastelPurple,
    },
    avatar: [
      colors.pastelGreen,
      colors.pastelPurple,
      colors.pastelBrown,
      colors.pastelBlue,
      colors.pastelBeige,
      colors.pastelYellow,
    ],
  },
};

export const notificationStyles: React.CSSProperties = {
  borderRadius: 10,
};

export default theme;
